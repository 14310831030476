<template>
    <section class="individual-contract">
        <div class="top-bar bg-white">
            <el-form ref="formSearch" :model="formSearch" label-width="100px" size="small" inline>
                <el-form-item label="合同编号" prop="name">
                    <el-input v-model="formSearch.contractNum" placeholder="请输入合同编号" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="合同状态" prop="contractStatus">
                    <el-select v-model="formSearch.contractStatus" placeholder="请选择合同状态" style="width: 200px;">
                        <el-option v-for="item in statusCodeList" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="房源名称" prop="apartmentName">
                    <el-input v-model="formSearch.apartmentName" placeholder="请输入房源名称" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="租户姓名" prop="leasorName">
                    <el-input v-model="formSearch.leasorName" placeholder="请输入租户姓名" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="手机号" prop="leasorPhone">
                    <el-input v-model="formSearch.leasorPhone" placeholder="请输入手机号" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="房租账单状态" prop="rentalBillStatus">
                    <el-select v-model="formSearch.rentalBillStatus" placeholder="请选择房租账单状态" style="width: 200px;">
                        <el-option v-for="item in rentalBillStatus" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="押金账单状态" prop="pledgeBill">
                    <el-select v-model="formSearch.pledgeBill" placeholder="请选择押金账单状态" style="width: 200px;">
                        <el-option v-for="item in pledgeBillStatus" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="蓝牙同步状态" prop="blueSyncStatus">
                    <el-select v-model="formSearch.blueSyncStatus" placeholder="请选择蓝牙同步状态" style="width: 200px;">
                        <el-option v-for="item in bluePasswordSyncStatus" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" @click="handleContractExport">导出数据</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getTableData" :columns="tableColumn"
                   :query="formSearch" :height="700">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="goDoor(row)">查看密码</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
import {tableColumn} from "@/views/rental-management/synthetical-management/data";
import {getContractBillLockStatusListApi, exportContractBillLockStatusListApi,} from "@/api/synthetical-management";
import {isbzf} from "@/api/house-resource";
import {downloadByData, base64ToBlobByPDF} from "@/utils/hooks/download";
import {timeFormat} from "@custom/index";
import {getFlowUrl, downloadContract, getContractData} from "@/api/contract";
import {MessageWarning} from "@custom/message";

export default {
    name: "synthetical-management",
    data() {
        return {
            tableColumn: tableColumn(this),
            formSearch: {
                contractNum: null,
                contractStatus: null,
                apartmentName: null,
                leasorName: null,
                leasorPhone: null,
                rentalBillStatus: null,
                pledgeBill: null,
                blueSyncStatus: null,
            },
            statusCodeList: [],
            rentalBillStatus: [
                {code: -1, codeName: '全部'},
                {code: 0, codeName: '异常'},
                {code: 1, codeName: '正常且支付'},
                {code: 2, codeName: '正常未支付'},
            ],
            pledgeBillStatus: [
                {code: -1, codeName: '全部'},
                {code: 0, codeName: '异常'},
                {code: 1, codeName: '正常且支付'},
                {code: 2, codeName: '正常未支付'},
            ],
            bluePasswordSyncStatus: [
                {code: -1, codeName: '全部'},
                {code: 0, codeName: '未同步'},
                {code: 1, codeName: '已同步'},
            ],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    methods: {
        getTableData(params) {
            return getContractBillLockStatusListApi(params);
        },
        handleSearch() {
            this.$refs["tableRef"].getTableData();
        },
        handleReset() {
            this.$refs['formSearch'].resetFields();
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        handleContractExport() {
            const that = this;

            const pageSize = this.$refs["tableRef"].pSize;
            const pageNumber = this.$refs["tableRef"].pNumber;
            let {
                contractNum, contractStatus, apartmentName, leasorName, leasorPhone, rentalBillStatus, pledgeBill,
                blueSyncStatus,
            } = this.formSearch;
            const paramData = {
                pageSize, page: pageNumber,contractNum, contractStatus, apartmentName, leasorName, leasorPhone,
                rentalBillStatus, pledgeBill, blueSyncStatus,
            };
            const loadingOptions = that.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            exportContractBillLockStatusListApi(paramData).then((res) => {
              console.log(res)
                downloadByData({data: res, filename: `综合管理${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.xlsx`,});
            }).finally(() => loading.close());
        },
        getElectronicContract(data) {
            const that = this;
            const {apartmentUuid, contractUuid} = data;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            isbzf(apartmentUuid).then(res => {
                if (res.info) {
                    that.getFlowUrl(contractUuid);
                } else {
                    that.getNuoNuoFlowUrl(contractUuid);
                }
            }).finally(() => loading.close());
        },

        getFlowUrl(uuid) {
            const that = this;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            getFlowUrl(uuid).then((res) => {
                let {msg} = res;
                let tempwindow = window.open("", "_blank");
                tempwindow.location = msg;
            }).finally(() => loading.close());
        },

        getNuoNuoFlowUrl(uuid) {
            const that = this;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            getContractData({contractUuid: uuid, uuid}).then(({info = {}}) => {
                let {contractStatus = null} = info;
                if (contractStatus === null || contractStatus === 1) {
                    MessageWarning("点前电子合同未完善")
                } else {
                    loading = that.$loading({...loadingOptions});
                    downloadContract(uuid).then(res => {
                        let {msg} = res;
                        if (msg) {
                            let blob = base64ToBlobByPDF(msg);
                            let pdfUrl = window.URL.createObjectURL(blob);
                            let tempwindow = window.open("", '_blank');
                            tempwindow.location = pdfUrl;
                        }
                    }).finally(() => loading.close());
                }
            }).finally(() => loading.close());
        },

        goDoor({apartmentName}) {
            this.$router.push({name: "door-management", params: {data: {apartmerntName: apartmentName}},});
        }
    },
    async mounted() {
        let {params: {data},} = this.$route;
        if (data) {
            const {contractNum} = data;
            if (contractNum) this.formSearch.contractNum = contractNum;
        }
        this.statusCodeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500700");
        this.statusCodeList = this.statusCodeList.filter(({code}) => code !== 500707 && code !== 500708);
        // this.handleSearch();
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
.individual-contract {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: center;

        .el-radio-button.is-active {
            box-shadow: 0 0 10px #ddd inset;
        }
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
