<template>
    <el-upload
            class="upload-video"
            :action="uploadInterfaceIp"
            :headers="headersToken"
            :name="name"
            :before-upload="handleBeforeUpload"
            :on-success="handleSuccess"
            :on-error="handleError"
            :accept="accept"
            :show-file-list="false"
    >

        <div class="avatar flex justify-center align-center" v-if="imageUrl">
            <img :src="imageUrl">
            <div class="el-upload-list__item-delete flex justify-center align-center">
                <i class="el-icon-delete" @click.stop="handleRemove"></i>
            </div>
        </div>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <div v-show="showTip" slot="tip" class="el-upload__tip">只能上传{{ accept }}文件</div>
    </el-upload>
</template>

<script>
    import {baseConfig} from "@/utils/config";
    import {tokenCookies} from "@storage/cookies";
    import {MessageError, MessageWarning} from "@custom/message";
    import {createFullImageUrl} from "@/components/Upload/index";

    export default {
        props: {
            // 文件的唯一值，当前系统为uuid
            uuid: {
                type: String,
                default: "",
            },
            name: {
                type: String,
                default: "file",
            },
            accept: {
                type: String,
                default: ".jpg,.jpeg,.png,.gif",
            },
            // 像素大小[width，height]
            pixel: {
                type: Array,
                default: () => [],
            },
            // 是否提示备注
            showTip: {
                type: Boolean,
                default: true,
            },
        },
        name: "upload-video",
        data() {
            return {
                uploadInterfaceIp: baseConfig.baseURL + "/applet/rent/files/upload",
                headersToken: {
                    "X-Access-Token": tokenCookies.get(),
                },
                imageUrl: this.uuid,
            };
        },
        watch: {
            uuid: {
                handler() {
                    // 回显图片
                    if (!this.uuid) return;
                    this.imageUrl ='https://img1.baidu.com/it/u=4255411982,4025002599&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500';
                },
                immediate: true
            }
        },
        methods: {
            handleBeforeUpload(file) {
                return new Promise((resolve, reject) => {
                    const {name} = file;
                    const isLt2M = file.size / 1024 / 1024 < 5;
                    // 上传文件格式
                    const acceptArray = this.accept.split(",");
                    const extensionIndex = name.lastIndexOf(".");       // 后缀序列
                    const extension = name.substring(extensionIndex);   // 文件后缀
                    this.fileName = name.substring(0, extensionIndex);  // 文件名称
                    let isFileType = acceptArray.includes(extension);
                    if (!isFileType) {
                        MessageWarning(`请上传${this.accept}格式的文件！`);
                        reject(false);
                    }
                    if (!isLt2M) {
                        MessageWarning('上传视频大小不能超过 5MB!');
                        reject(false);
                    }
                    resolve(isFileType);
                });
            },
            handleRemove(file) {
                // 删除
                this.imageUrl = null;
                this.$emit("on-remove", file);
            },
            handleSuccess(response, file) {
                // 上传成功
                const {resultCode = -1} = response;
                if (resultCode === 1000) {
                    this.imageUrl = 'https://img1.baidu.com/it/u=4255411982,4025002599&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500';
                    const fileName = this.fileName;
                    const info = response.returnObject.info;
                    this.$emit("on-success", {fileName, file, info});
                } else {
                    this.handleError();
                }
            },
            handleError() {
                MessageError("文件上传失败", 1000, () => {
                    this.fileList = [];
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .upload-video /deep/ .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 148px;
            height: 148px;
            line-height: 148px;
            text-align: center;
        }

        .avatar {
            width: 148px;
            height: 148px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .el-upload-list__item-delete {
                width: 100%;
                height: 100%;
                color: #fff;
                font-size: rem(25px);
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
                transition: opacity .3s;
            }

            &:hover .el-upload-list__item-delete {
                display: flex;
            }
        }
    }
</style>
