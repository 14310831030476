<template>
  <r-e-dialog title="租金修改申请" class="layer-contract-detail" :visible.sync="dialogVisible" :show-footer="type!=='chakan'"
              top="10vh"
              width="670px" :showFooter="false"
              fullscreen @close="clickCancel">
    <div style="height: 75vh;">
      <div style="height: 100%;width: 100%;">
        <el-steps :active="active" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
          <el-step title="楼栋管理员发起">
            <div slot="description">
              <div>发起人:</div>
              <div>发起时间:</div>
            </div>
          </el-step>

          <el-step title="资产部审核">
            <div slot="description" v-if="active>=2">

              <div>审核人:</div>
              <div>审核时间:</div>
            </div>
          </el-step>
          <el-step title="财务部审核">
            <div slot="description" v-if="active>=2">

              <div>审核人:</div>
              <div>审核时间:</div>
            </div>
          </el-step>
          <el-step title="管理员审核">
            <div slot="description" v-if="active>=2">
              <div>管理员审核:</div>
              <div>审核人:</div>
              <div>审核时间:</div>
            </div>
          </el-step>
          <el-step title="查看"></el-step>
        </el-steps>

        <div style="height: 90%;display: flex;flex-flow: column wrap;">

          <div>
            <div class="box_tap">
              <h3>房源信息</h3>
            </div>
            <div class="box_bnt">
              <div class="item">
                房间：{{formPublish.apartmentName}}
              </div>
              <div class="item">
                房型：{{formPublish.hourseLayout}}
              </div>
              <div class="item">
                建筑面积：{{formPublish.outsideArea}}㎡
              </div>
              <div class="item" style="display: flex">
                 <div v-if="formPublish.myNmae === '单价'" style="width: 302px">单价(元/㎡)：{{formPublish.monthlyRent/100}}</div>
                <div  v-if="formPublish.myNmae === '单价'">租金(元/月)：{{(formPublish.monthlyRent/100)* formPublish.outsideArea}}</div>
                <div  v-if="formPublish.myNmae !== '单价'">租金(元/月)：{{formPublish.monthlyRent/100 }}</div>
              </div>
<!--              <div class="item">-->
<!--                地址：{{formPublish.apartmentAddress}}-->
<!--&lt;!&ndash;                押金：{{formPublish.depositAmount/100}}元&ndash;&gt;-->
<!--              </div>-->
              <div class="item" style="display: flex;align-items: center">
                地址：{{formPublish.apartmentAddress}}
              </div>
              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px" v-if="formPublish.myNmae !== '单价'">申请修改租金：</div>
                <div style="width: 100px" v-if="formPublish.myNmae === '单价'">申请修改单价：</div>
                <el-input  style="width: 100px" v-model="rentUpdatePrice" placeholder="请输入申请价格"></el-input>元
                <!--                <div v-if="formPublish.workOrderStatus !== '待发起'"  style="border-bottom: solid 1px #BBBBBB;width: 200px;text-align: center">{{formPublish.rentUpdatePrice}}元</div>-->
              </div>
              <div class="item" style="display: flex;align-items: center" v-if="formPublish.myNmae === '单价'">
                <div style="width: 250px">修改后租金（单价*面积=月租金）：</div>
                <!--                  <el-input v-if="formPublish.workOrderStatus === '待发起'"  style="width: 250px;margin-top: 20px" v-model="formPublish.rentUpdateReason" placeholder="请输入内容"></el-input>-->
                <div  style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center;height: 25px">{{money}}</div>元
              </div>
              <div v-else class="item">
              </div>
              <div class="item">
<!--                半年租金折扣：{{formPublish.sixMonthDiscount}}%-->
              </div>
              <div class="item">
<!--                年租金折扣：{{formPublish.yearDiscount}}%-->
              </div>
              <div class="item">
<!--                地址：{{formPublish.apartmentAddress}}-->
              </div>


              <div class="item">
              </div>

              <div class="item">
              </div>

              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请原因：</div>
                <el-input type="textarea"  style="width: 350px;margin-top: 20px" v-model="rentUpdateReason" placeholder="请输入内容"></el-input>
              </div>
<!--              <div class="item">-->
<!--                <div style="display: flex;align-items: center">-->
<!--                  <div style="width: 100px">申请修改租金：</div><el-input style="width: 150px" v-model="rentUpdatePrice" placeholder="请输入申请价格"></el-input>元-->
<!--                </div>-->
<!--                <div style="height: 30px"></div>-->
<!--                <div class="item" style="display: flex;align-items: center">-->
<!--                  <div style="width: 100px">申请原因：</div><el-input type="textarea"    style="width: 350px" v-model="rentUpdateReason" placeholder="请输入内容"></el-input>-->
<!--                </div>-->
<!--              </div>-->
<!--&lt;!&ndash;              人才基地小区&ndash;&gt;-->
<!--              <div class="item" style="display: flex;align-items: center;margin-bottom: 63px" v-if="formPublish.myNmae === '单价'">-->
<!--                <div style="width: 230px"> 修改后租金（单价*面积=月租金）：</div>-->

<!--                <div style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center;margin-bottom: -14px">{{money}}</div>元-->
<!--              </div>-->

              <div class="item">
              </div>
              <div class="item">
              </div>
              <div class="item">
              </div>
              <div class="item" >
                附&emsp;&emsp;件：<a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" >添加附件</a>
              </div>
            </div>
          </div>
          <div style="height: 40px"></div>
          <div style="height: 40px"></div>
          <div>
            <el-badge :value="formPublish.handleNum" :max="99" class="item">
              <el-button @click="chuLijilu" size="small" type="primary">处理记录</el-button>
            </el-badge>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center;margin-top: 50px">
      <el-button size="small" @click="clickCancel" style="width: 80px;background-color: #D8E7FC;border: none">取消
      </el-button>
<!--      <el-button size="small" @click="bohuiclick" type="primary"-->
<!--                 style="width: 80px;margin-left: 40px;background-color: #72BEDF;border: none">驳回-->
<!--      </el-button>-->
      <el-button size="small" @click="clickSubmitBaocun" type="primary"
                 style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">保存
      </el-button>
      <el-button size="small" @click="clickSubmit" type="primary"
                 style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交
      </el-button>
    </div>

    <!--      处理记录弹框-->
    <r-e-dialog title="处理记录" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                @click-cancel="billListClose" v-dialog-drag>

      <r-e-table :query="formSearch"  class="bg-white" ref="orderTableRef" :dataRequest="getWordListTableData" :columns="tableColumn"
                 tooltip-effect="light" :height="500">
        <template slot="empty">
          <el-empty/>
        </template>

      </r-e-table>
    </r-e-dialog>
    <!--      驳回原因弹框-->
    <r-e-dialog title="驳回" :visible.sync="bohuiSwitch" show-footer top="30vh" width="500px" :showFooter="false"
                @click-cancel="billListClose" v-dialog-drag>

      <el-form  :rules="rules">
        <el-form-item prop="remark"  label-width="80px" label="驳回原因">
          <el-input :rows="4" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top: 50px">
        <el-button size="small" @click="quxiaoClick" style="width: 80px;background-color: #D8E7FC;border: none">取消</el-button>

        <el-button size="small" type="primary" @click="bohuiyuanyClick" class="button_small_bohui">提交
        </el-button>
      </div>
    </r-e-dialog>
    <dialog-save-contract-file ref="dialogSaveContractFile" :currentHouse="formSearch"
                               :contractInfo="formSearch" @attachment-click="attachmentClick" />
  </r-e-dialog>

</template>

<script>
import {
  getWordList,
  getRentSubmit,
  getRentSubmitPUT,
  getHousingCodeInfoPOST,
  getHousingCodeInfoGET
} from "@/api/work-order-management";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {timeFormat,  numberFormat} from "@custom";
import {tableWorkOrderColumn} from "@/views/rental-management/house-resource/data";
import DialogSaveContractFile
  from "@/views/rental-management/approval-process-workorder/components/pant/dialog-save-contract-file.vue";

export default {
  name: "dialog-huanfang",
  data() {
    return {
      canClick:true,//节流阀
      money:null,
      tableColumn: tableWorkOrderColumn(this),
      rentUpdatePrice:null,
      rentUpdateReason:null,
      dialogVisible: false,
      bohuiSwitch: false,
      active: 0,
      formPublish: {},
      formSearch:{},
      uuid: null,
      state: null,
      billTitle: null,
      billSwitch: false,
      billList: [],
      submitter: null,
      submitDate: null,
      type: null,
      attachment:'',//附件uuid集合
      rules: {
        remark: [
          { required: true, message: '请输入原因', trigger: 'blur' },
        ],

      }
    };
  },
  components: {
    DialogSaveContractFile
    // uploadPictureCard: ()=>import("@/components/Upload/upload-picture-card"),
  },
  methods: {
    // 保存事件
    clickSubmitBaocun(){
      console.log(this.formPublish)
      if(!this.rentUpdatePrice){
        MessageWarning('请填写金额')
        return
      }
      if(!this.rentUpdateReason){
        MessageWarning('请填写申请原因')
        return
      }

      if(!this.isNumber(this.rentUpdatePrice)){
        MessageWarning('请规范输入租金价格')
        return
      }
      // 节流阀开始
      // setTimeout(function() {
      //   this.canClick = true;
      // }, 3000); // 5秒后允许再次点击
      // if(!this.canClick){
      //   MessageWarning('您操作太快，请稍后再试！')
      //   return
      // }
      // this.canClick = false
      let {outsideArea,monthlyRent,rentalAmountType } = this.formPublish
      // 节流阀结束
      let data = {
        apartmentUuid: this.apartmentUuid,
        rentUpdatePrice: this.rentUpdatePrice,
        rentUpdateReason: this.rentUpdateReason,
        attachment: this.attachment,
        outsideArea,
        monthlyRent,
        rentalAmountType
      }
      if(this.formPublish.id) data.id = this.formPublish.id
      if(this.formPublish.id) this.formSearch.id = this.formPublish.id
      getHousingCodeInfoPOST(data).then(res => {

        console.log(res)
        MessageSuccess('保存成功')
        this.clickCancel()

      })
    },
    getWordListTableData(params) {
      return getHousingCodeInfoGET(params);
    },
    async openDialog(apartmentUuid, ) {
      // 打开弹框事件
      let that = this;
      this.apartmentUuid = apartmentUuid
      getRentSubmit({apartmentUuid: apartmentUuid}).then(({info}) => {
        // apartmentName.substring(0, 7)   info.apartmentName.substring(0, 7) === '林里桥家园小区'
        //
        if(info.rentalAmountType	 === 0) {
          // 计租方式 0：固定金额 1：面积乘单价
          info.myNmae = '月租'
        }else {
          info.myNmae = '单价'
        }
        this.formPublish = info
        console.log(this.formPublish)
        // console.log(res,'详情')
      })
      that.dialogVisible = true;
    },
    chuLijilu() {
      if(!this.formSearch.id){
        MessageWarning('暂无记录')
        return
      }
      // 这里添加处理记录的逻辑
      this.billSwitch = true
      console.log('点击了处理记录按钮');
    },
    clickSubmit() {
      if(!this.rentUpdatePrice) {
        MessageWarning('请检查是否填写完整');
        return
      }
      if(!this.rentUpdateReason) {
        MessageWarning('请检查是否填写完整');
        return
      }
      if(!this.isNumber(this.rentUpdatePrice)){
        MessageWarning('请规范输入租金价格')
        return
      }
      // 节流阀开始
      setTimeout(function() {
        this.canClick = true;
      }, 5000); // 5秒后允许再次点击
      if(!this.canClick){
        MessageWarning('您操作太快，请稍后再试！')
        return
      }
      // this.canClick = false
      let {outsideArea,monthlyRent,rentalAmountType } = this.formPublish
      let data = {
        apartmentUuid:this.apartmentUuid,
        rentUpdatePrice:this.rentUpdatePrice,
        rentUpdateReason:this.rentUpdateReason,
        attachment: this.attachment,
        outsideArea,
        monthlyRent,
        rentalAmountType
      }
      // 节流阀结束
      getRentSubmitPUT(data).then(res => {
        MessageSuccess('租金审批提交成功')
        this.clickCancel()
      })
    },
    bohuiyuanyClick(){
      MessageSuccess('驳回原因提交成功')
    },
    clickCancel() {
      // 取消返回事件
      this.$emit('handleSearch');
      this.rentUpdatePrice = null,
          this.rentUpdateReason = null,
      this.dialogVisible = false;
      this.formPublish = {};
      this.money = null
    },
    quxiaoClick(){
    //   驳回原因取消
      this.bohuiSwitch = false
    },
    numberFormat(number = 0) {
      number = parseFloat(number);
      return numberFormat(number);
    },

    timeFormat(timedate, format = "yyyy-MM-dd") {
      if (timedate) return timeFormat(new Date(timedate), format);
      else return "";
    },
    billListClose() {
      // 关闭处理弹框事件
      this.billTitle = null;
      this.billList = null;
      this.billSwitch = false;
    },
    bohuiclick() {
      // 驳回事件打开驳回弹框
      this.bohuiSwitch = true;
    },
    isNumber(value) {
      // 判断是否是数字并且大于0
      if (typeof value === 'number' && !isNaN(value) && value > 0) {
        return true;
      }

      // 判断是否是可以转换为大于0的数字的字符串
      if (typeof value === 'string') {
        const numberValue = Number(value);
        return !isNaN(numberValue) && numberValue > 0;
      }

      // 其他情况返回 false
      return false;
    },
    // 上传pdf事件
    funUrlPdf(){
      this.formSearch.attachmentImg ? this.formSearch.contractPhotos = 'IMG+-+' + this.formSearch.attachmentImg : this.formSearch.contractPhotos = null
      this.formSearch.attachmentPdf ?  this.formSearch.pdfUrl = 'PDF+-+' +  this.formSearch.attachmentPdf: this.formSearch.pdfUrl = null
      //   IMG+-+ PDF+-+
      // if(this.formPublish)
      console.log(this.formSearch,'formPublish--传子组件数据')
      this.$refs["dialogSaveContractFile"].openDialog(this.formSearch);
    },
    // 上传附件
    attachmentClick(uuid){
      console.log(uuid)
      // alert(uuid)

      this.attachment = [...uuid.filePDFList,...uuid.imgList].join(",")
      console.log(this.formPublish,'上传完点击确认然后回馈给父组件')
    },
  },
  watch: {
    rentUpdatePrice(val) {
      console.log(val)
      if (val) {
        // console.log('999')
        if(this.isNumber(val)){
          this.money = (val * this.formPublish.outsideArea).toFixed(2);
          return
        }
        MessageWarning('请规范输入租金价格')
      }
    }
}
}
</script>

<style scoped>
.row {
  margin-bottom: 10px;
  padding: 10px 20px;
  border: 1px solid #D7D7D7;
}

.width300 {
  width: 300px;
}

.width100 {
  width: 100px;
}

.boder-right {
  border-right: 1px solid #D7D7D7;
}

.title {
  border-left: 5px #F5A623 solid;
  padding-left: 5px;
  margin: 15px 0;
  font-weight: 600;
}

.bg-E6E6E6 {
  background-color: #E6E6E6;
}

.bg-E2F5FF {
  background-color: #E2F5FF;
}

.bg-F8F8F8 {
  background-color: #F8F8F8;
}

.width33b {
  width: 25%;
}

.padding_0_30 {
  padding: 0 30px
}

.padding_10_30 {
  padding: 10px 30px;
}

.fw600 {
  font-weight: 600;
}

.box_tap {
  border-left: #EEB765 2px solid;
  width: 500px;
  height: 29px;
  line-height: 29px;
  padding-left: 5px;
}

.box_bnt {
  border: #BBBBBB solid 1px;
  width: 100%;
  height: 300px;
  margin-top: 30px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 项目之间的间距，可选 */
  padding: 10px; /* 容器内边距 */
}

.item {
  flex: 1 1 calc(25% - 20px); /* 每个项目占据25%宽度减去间距 */
  color: black;
}
.button_small_bohui{
  width: 80px;
  margin-left: 40px;
  background-color: #409EFF;
  border: none
}
.el-input{
  .el-input__inner{
    border: none;
    border-bottom: #8c939d 1px solid;
    border-radius: 0px;
  }
}

</style>
