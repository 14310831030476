<template>
  <div>
    <div style="padding: 0 50px;font-size: 1.17em;user-select:none;-moz-user-select:none;-webkit-user-select:none;">
      <div class="text-right" style="padding-right: 200px;">合同编号：{{ cmData.contractorNum }}</div>
      <h1 class="text-center">房 屋 租 赁 合 同</h1>
      <div class="text-indent padding_5_0">出租方（甲方）：
        <span class="span_decoration">&nbsp;&nbsp;广西南宁昇达资产投资有限公司&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">地 址：
        <span class="span_decoration">&nbsp;&nbsp;南宁市青秀区凤起路5 号宁大厦 904 室
&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">联系电话：
        <span class="span_decoration">&nbsp;&nbsp;0771-5826733&nbsp;&nbsp;</span>
      </div>
      <div style="height: 44px"></div>
      <div class="text-indent padding_5_0">
        承租方（乙方）： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.lesseeName }}&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">
        乙方证件号码： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.lesseeIdCard }}&nbsp;&nbsp;</span>
      </div>

      <div class="text-indent padding_5_0">
        地 址： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.Address }}&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">
        联系电话： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.phone}}&nbsp;&nbsp;</span>
      </div>
      <!--      <div class="text-indent padding_5_0">-->
      <!--      承租方（乙方）： <span class="span_decoration">{{ cmData.lesseeName }}</span>-->
      <!--      身份证号： <span class="span_decoration">{{ cmData.lesseeIdCard }}</span>-->
      <!--      联系地址： <span class="span_decoration">{{ cmData.Address }}</span>-->
      <!--    </div>-->

      <!--      <div class="text-indent padding_5_0">-->
      <!--        手机号码： <span class="span_decoration">{{ cmData.phone }}</span>-->
      <!--        紧急联系人：<span class="span_decoration">{{ cmData.contractName }}</span>-->
      <!--        紧急联系电话： <span class="span_decoration">{{ cmData.contractPhone }}</span>-->
      <!--      </div>-->
      <div style="height: 20px;"></div>
      <div class="text-indent">
        根据《中华人民共和国民法典》《中华人民共和国城市房地产管理法》《商品房屋租赁管理办法》《南宁市房屋租赁管理办法》及相关法律、法规的规定，双方在自愿、平等、公平及诚实信用的基础上，就甲方将房屋出租给乙方相关内容协商一致，签订本房屋租赁合同。
      </div>
      <div style="height: 20px;"></div>
      <div class="text-indent">
        特别约定：
      </div>
      <div class="text-indent">
        鉴于{{cmData.apartmentType}}保障性租赁住房，甲乙双方须严格遵守并执行相关政府部门关于保障性租赁住房的相关要求(包括但不限于租金评估及租金涨幅的要求)
      </div>
      <div style="height: 20px;"></div>
      <h3 class="padding_5_0 margin_0 text_ind_17">一、租赁房屋基本情况</h3>
      <div class="text-indent padding_5_0">
        1.1租赁房屋位置：
        <span class="span_decoration">&nbsp;&nbsp;南宁市{{ cmData.locationAddress }}&nbsp;&nbsp;</span>；
        房屋面积为 <span class="span_decoration">&nbsp;&nbsp;{{ cmData.outArea }}&nbsp;&nbsp;</span> ㎡，其中套内面积为 <span
          class="span_decoration">&nbsp;&nbsp;{{ cmData.useArea }}&nbsp;&nbsp;</span> ㎡。
       {{ cmData.roomNum }}居室
      </div>
      <div class="text-indent padding_5_0">
        房屋类型：住宅
      </div>
      <div class="text-indent padding_5_0">
        装修情况：【{{cmData.decorationStyle}}】，【{{cmData.elevator}}】电梯
      </div>
      <div class="text-indent padding_5_0">
        1.2租赁用途：该房屋的租赁用途为【居住】。如租赁用途为居住的，居住人数为：<span class="span_decoration">&nbsp;&nbsp;1&nbsp;&nbsp;</span>
        人。乙方和实际居住人不一致的，乙方应当向甲方提供实际居住人的信息。如租赁过程中发现存在其他与本约定不符之用途，则视为乙方违约，甲方有权要求乙方整改或单方面解除双方租赁合同。
      </div>
      <div class="text-indent padding_5_0">
        1.3本合同租赁期满或者提前终止时，乙方须根据本合同签署时甲乙双方签订的房屋交接及设备清单，将相关房屋、家具家电及设施设备等如数按时且功能完整的归还甲方，并于移交之日由双方再次签署相关房屋交还及设施设备交还确认书。（以实际验收的品种和数量为准）
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">二、租赁期限</h3>
      <div class="text-indent padding_5_0">
        2.1 租赁期限：自 <span class="span_decoration">&nbsp;&nbsp;{{ cmData.contractStartTime }}&nbsp;&nbsp;</span>起至 <span
          class="span_decoration">&nbsp;&nbsp;{{ cmData.contractEndTime }}&nbsp;&nbsp;</span>止。
      </div>
      <div class="text-indent padding_5_0">
        租赁期满，在同等条件下，乙方有优先承租权，乙方存在重大违约行为的除外。乙方有意续租的，应在租赁期满前【30】日以书面形式向甲方提出，超过上述期限未提出的，视为乙方放弃优先承租权。甲方在收到乙方续租意向之日起【10】日内给予明确回复，甲方同意乙方继续承租的，双方重新签订租赁合同；若甲方未在上述期限内回复的，视为甲方同意乙方以本合同约定条件及期限续租。如乙方不续租的，则应在租赁期满前提前【30】日通知甲方，且应在租赁期满时腾空并向甲方交付租赁房屋。
      </div>
      <div class="text-indent padding_5_0">
        租赁期满，甲方不再继续出租房屋的，应当至少提前【30】日通知乙方。
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">三、租金标准及支付</h3>
      <div class="text-indent padding_5_0">
        3.1租金单价为：人民币（小写） <span class="span_decoration">&nbsp;&nbsp;{{ cmData.unitPrice }}&nbsp;&nbsp;</span> 元/（每月），租金总计：人民币（小写）
        <span class="span_decoration">&nbsp;&nbsp;{{ cmData.rentalAmount }}&nbsp;&nbsp;</span> 元（大写： <span
          class="span_decoration">&nbsp;&nbsp;{{ cmData.rentalAmountUppercase }}&nbsp;&nbsp;</span> ）。
      </div>
      <div class="text-indent padding_5_0">
        3.2租金按【月】支付，首期租金支付日期为 <span class="span_decoration">&nbsp;&nbsp;{{ cmData.signDate }}&nbsp;&nbsp;</span>  ，之后每笔租金在租赁时间发生前五日内支付（详见租金支付周期表）。房屋租赁期限内，租金不调整。
      </div>
      <div class="text-indent padding_5_0">
        押金：人民币（小写） <span class="span_decoration">&nbsp;&nbsp;{{ cmData.depoAmount }}&nbsp;&nbsp;</span> 元（大写：<span class="span_decoration">&nbsp;&nbsp;{{ cmData.depoAmountUppercase }}&nbsp;&nbsp;</span> ），乙方应当于<span class="span_decoration">&nbsp;&nbsp;{{ cmData.pledgeDate }}&nbsp;&nbsp;</span>前通过【转账】向甲方支付押金。
      </div>
      <div class="text-indent padding_5_0">
        甲方收取押金后，应向乙方开具收款凭证。押金除用于抵扣乙方应交而未交的租金、费用以及乙方应当承担的违约金、赔偿金外，剩余部分应在房屋交还之日起3个工作日内如数无息返还乙方；不足以抵扣的，乙方应据实予以补足。甲方不得无故扣留乙方押金拒不退还。
      </div>
      <div class="text-indent padding_5_0">
        3.3租金及押金通过转账的方式向甲方支付；
      </div>
      <div class="text-indent padding_5_0">
        开户行：<span class="span_decoration">&nbsp;&nbsp;交通银行南宁银杉支行&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">
        户 名：<span class="span_decoration">&nbsp;&nbsp;广西南宁昇达资产投资有限公司&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">
        账 号：<span class="span_decoration">&nbsp;&nbsp;451060700013001093984&nbsp;&nbsp;</span>
      </div>
      <div class="text-indent padding_5_0">
        3.4其他费用
      </div>
      <div class="text-indent padding_5_0">
        租赁期间，【水费】【电费】【燃气费】【物业管理费】【车位费】【网络费】【垃圾费】【维修费】由乙方承担。乙方应当自收到缴费通知或甲方提供的收费凭据后按要求及时缴交费用，否则因此产生的滞纳金、违约金及相关法律后果均由乙方承担。
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">四、房屋交付与交还</h3>
      <div class="text-indent padding_5_0">
        4.1甲方应于<span class="span_decoration">&nbsp;&nbsp;{{ cmData.contractStartTime }}&nbsp;&nbsp;</span> 前将房屋按约定条件交付给乙方。双方经房屋交验，在《房屋交接及设备清单》中签字盖章后视为交付完成。甲方应在房屋交付前结清此前产生的管理费、水电费、煤气费等相关费用。
        <!--        <span class="span_decoration">{{ cmData.depoAmount }}</span> 元（大写：-->
        <!--        <span class="span_decoration">{{ cmData.depoAmountUppercase }}</span>）。-->
      </div>
      <div class="text-indent padding_5_0">
        4.2租赁期满或合同解除后，甲方有权收回房屋，乙方应当在租赁期满当日或合同解除当日搬离，并按照正常使用后的状态交还房屋及其附属物品、设施设备。且应保持清洁卫生，无垃圾，地面、墙面无明显污渍等，如不清理，甲方有权从乙方房屋押金中扣除清理费。乙方应保持房屋及设施、设备的完好状态（如地板、墙面、吊顶、门窗、敷设的水电线路等），不能影响房屋的正常使用；如出现房屋、配套设施、房屋的装饰装修的损坏，乙方负责维修或赔偿，双方应当对房屋和附属物品、设施设备及水电气热等使用情况进行交验，乙方应当结清其应承担的费用，双方应当在《房屋交还及设施设备交还确认书》中签字盖章。甲方返还押金后视为房屋腾退完成。
      </div>
      <div class="text-indent padding_5_0">
        乙方应负责对房屋中属于乙方的物品进行搬离处理，乙方未在本合同约定期限搬离的，乙方遗留在房屋中的物品，视为乙方放弃其所有权，甲方有权自行处理。
      </div>
      <div class="text-indent padding_5_0">
        4.3房屋维修约定
      </div>
      <div class="text-indent padding_5_0">
        4.3.1在房屋租赁过程中，房屋内部的维护维修由乙方自行负责，亦可支付费用与甲方协商由甲方代为处理。（详见附件房屋及附属设施家电家具相关费用计收标准）
      </div>
      <div class="text-indent padding_5_0">
        4.3.2物业管理区域内房屋的共用部位、共用设施设备的维修养护根据相关法律法规执行。
      </div>
      <div class="text-indent padding_5_0">
        4.3.3因乙方使用不当或不及时维修、维保，致使租赁房屋或设施设备、家具家电出现损坏或发生故障，属于乙方负责维修的项目，乙方有权自主选择维修单位，其费用标准及支付方式等具体事项由乙方与维修单位双方自行约定。若乙方拒不维修，则甲方代为维修，维修费用由乙方承担，且甲方有权直接从租赁押金中扣除相关费用。
      </div>
      <div class="text-indent padding_5_0">
        4.3.4因乙方使用、维修不当发生安全事故造成甲方及第三方人身、财产损失的，一切法律及赔偿责任由乙方自行承担，与甲方无关。
      </div>
      <div class="text-indent padding_5_0">
        4.3.5甲方发现乙方行为涉及损害或拆除房屋本身结构安全及甲方提供的设备设施的，甲方有权组织施工力量进行排除险情，乙方必须承担维修排危的费用，同时乙方承担相应责任。
      </div>
      <div class="text-indent padding_5_0">
        4.3.6甲方需要对房屋进行安全检查、维修、保养、改造的，乙方应按甲方要求给予积极配合。
      </div>
      <div class="text-indent padding_5_0">
        4.3.7因乙方原因导致不能及时检查、维修房屋的，乙方承担违约责任，导致房屋安全事故的，乙方承担全部法律责任。
      </div>
      <div class="text-indent padding_5_0">
        4.4装饰装修约定
      </div>
      <div class="text-indent padding_5_0">
        4.4.1甲方出租的房屋已完成装修装饰，乙方不得再进行二次装饰装修，并禁止出现以下行为：
      </div>
      <div class="text-indent padding_5_0">
        （一） 将没有防水要求的房间或者阳台改为卫生间、厨房间；

      </div>
      <div class="text-indent padding_5_0">
        （二） 扩大承重墙上原有的门窗尺寸，拆除连接阳台的砖、混凝土墙体；
      </div>
      <div class="text-indent padding_5_0">
        （三） 改变住宅外立面，在非承重外墙上开门、窗或其它拆改门窗的行为；
      </div>
      <div class="text-indent padding_5_0">
        （四） 损坏房屋原有节能设施，降低节能效果；
      </div>
      <div class="text-indent padding_5_0">
        （五） 电气线路等由暗敷改为明敷；
      </div>
      <div class="text-indent padding_5_0">
        （六） 其他影响建筑结构和使用安全的行为。
      </div>
      <div class="text-indent padding_5_0">
        乙方未经甲方批准擅自进行装饰装修活动的，应按擅自装修的套数向甲方支付违约金人民币2000元/套，并按甲方要求恢复原状，甲方有权解除租赁合同，收回房屋。
      </div>
      <div class="text-indent padding_5_0">
        4.4.2未经甲方书面同意，乙方擅自装饰装修，不论租赁合同因何种原因终止或提前解除，乙方已形成附合的装饰装修物归甲方所有，甲方不给予乙方任何补偿。
      </div>
      <div class="text-indent padding_5_0">
        4.4.3租赁期满或其他原因导致本合同终止时，乙方应按照入住前房屋原状返还房屋，若出现设施设备损坏，乙方须无条件进行恢复原状（如地板、墙面、吊顶、门窗、敷设的水电线路等），不能影响房屋的正常使用；如出现房屋、配套设施、房屋的装饰装修的损坏，乙方负责维修或赔偿）。
      </div>
      <div class="text-indent padding_5_0">
        4.5房屋使用安全的约定
      </div>
      <div class="text-indent padding_5_0">
        4.5.1乙方是房屋使用安全的第一责任人，甲方对乙方因自身管理原因所发生的任何人身伤亡、伤残、疾病或财产损失等不承担任何责任。乙方在房屋使用过程中，不得擅自从事下列行为：
      </div>
      <div class="text-indent padding_5_0">
        （一） 在房屋内或公共部位堆放易燃易爆或有毒物质等危险物品；
      </div>
      <div class="text-indent padding_5_0">
        （二） 在房屋内或公共部位超负荷堆放物品；
      </div>
      <div class="text-indent padding_5_0">
        （三） 在房屋用地或公共部位范围内乱搭乱盖建（构）筑物，或在房屋顶层加建、扩建；
      </div>
      <div class="text-indent padding_5_0">
        （四） 其他危及房屋结构安全的行为。
      </div>
      <div class="text-indent padding_5_0">
        4.6乙方应督促房屋使用人加强对家庭内有精神病患者、智力残障者、老年痴呆者、未成年人等的管护，一是避免其独自留守家中；二是提醒其不要玩火、玩打火机和燃烧物品，家中易燃物品、打火机、通电设备等应妥善保管或放置，避免其轻易获取；三是引导家庭成员改变不良生活习惯，避免安全事故发生。
      </div>
      <div class="text-indent padding_5_0">
        4.7乙方应自觉接受消防部门、派出所、社区居委会、小区物业服务中心和甲方的消防安全检查，并积极主动落实整改。
      </div>
      <div class="text-indent padding_5_0">
        4.8家具、家电使用约定
      </div>
      <div class="text-indent padding_5_0">
        4.8.1如甲方出租给乙方房屋配有家具、家电的，乙方在租赁期间内的使用过程中，对所配置的家具和家电均应加以爱护，对配置的家具和家电负责维护、保养及易耗品的更新，并承担所有费用。电器在保修期内出现质量问题的，乙方可自行拨打售后服务电话联系维修。质量保修期外，家具和家电的维修责任由乙方负责并承担维修费。如因使用不当造成配置家具和家电损坏的，均由乙方立即负责修缮或承担经济赔偿。因设施设备丢失、被盗、损坏，而使甲方遭受损失的，由乙方承担相应责任和费用，涉及第三方责任的，由乙方自行负责。
      </div>
      <div class="text-indent padding_5_0">
        4.8.2乙方在承租的房屋内，不得种植产生异味、对房屋造成破坏的植物，保持和加强室内清洁卫生。
      </div>
      <div class="text-indent padding_5_0">
        4.8.3本合同终止之日，乙方应无条件返还甲方房屋内完好的家具和家电，并保证能正常使用。
      </div>
      <div class="text-indent padding_5_0">
        4.9消防安全责任的约定
      </div>
      <div class="text-indent padding_5_0">
        4.9.1根据我国《中华人民共和国民法典》、《中华人民共和国消防法》等法律法规的规定以及“谁使用谁负责”的原则，乙方作为房屋使用人，是房屋责任人，对房屋的消防安全承担全部管理、使用责任。
      </div>
      <div class="text-indent padding_5_0">
        4.9.2租赁期间，乙方应按以下约定规范用电，并对本房屋所安装、使用的全部电气线路、设施设备承担全部安全责任，且应规范用电，不得有下列危及用电安全的行为：
      </div>
      <div class="text-indent padding_5_0">
        （1）私自拉接电线或使用不合格的电线、插座、开关等设备或超负荷用电；
      </div>
      <div class="text-indent padding_5_0">
        （2）使用质量不合格或不满足负荷要求的移动电线及排插；
      </div>
      <div class="text-indent padding_5_0">
        （3）使用质量不合格或存在消防安全隐患的电器产品；
      </div>
      <div class="text-indent padding_5_0">
        （4）从事其他危险用电行为；
      </div>
      <div class="text-indent padding_5_0">
        （5）电动车辆按照小区停放区划分规定停放、充电。禁止电动车辆私接乱拉电线充电，严禁将电动车辆运送至非停放区的楼道或房屋内。
      </div>
      <div class="text-indent padding_5_0">
        4.9.3租赁期间，乙方应按下列约定规范用火，并对其用火安全承担全部责任：
      </div>
      <div class="text-indent padding_5_0">
        （一）不得在房屋内或公共走道等非厨房部位生火煮食；
      </div>
      <div class="text-indent padding_5_0">
        （二）不得在房屋内外摆设产生明火的神台香炉等；
      </div>
      <div class="text-indent padding_5_0">
        （三）不得在房屋内或公共部位存放易燃、易爆或有毒物品；
      </div>
      <div class="text-indent padding_5_0">
        （四）使用合格的炉灶、排烟设备，并按使用说明书定时清洗，确保清洁、无油烟堆积；
      </div>
      <div class="text-indent padding_5_0">
        （五）安全使用燃气、煤气等；
      </div>
      <div class="text-indent padding_5_0">
        （六）不得有其他危险用火行为。
      </div>
      <div class="text-indent padding_5_0">
        4.9.4租赁期间，乙方应保持房屋内部及周边消防（公共）通道和出入口的畅通，不得有下列堵塞消防（公共）通道和出入口的行为：
      </div>
      <div class="text-indent padding_5_0">
        （一）占用公共走道、消防通道等乱堆杂物或改为其他用途；
      </div>
      <div class="text-indent padding_5_0">
        （二）在公共通道、楼梯间等处安装向内开启的各类门；
      </div>
      <div class="text-indent padding_5_0">
        （三）其他堵塞消防通道的行为；
      </div>
      <div class="text-indent padding_5_0">
        （四）占用逃生避难层堆放杂物或改为其他用途。
      </div>
      <div class="text-indent padding_5_0">
        4.9.5由于乙方对房屋的消防安全承担全部管理责任，房屋发生火灾等消防安全事故的（除人为纵火外），乙方负全部责任。
      </div>

      <h3 class="padding_5_0 margin_0 text_ind_17">五、双方各自的权利与义务</h3>
      <h4 class="padding_5_0 margin_0 text_ind_17">5.1甲方的权利与义务</h4>
      <div class="text-indent padding_5_0">
        5.1.1保证拥有完全的资格和权利将房屋按本合同之约定租赁给乙方。
      </div>
      <div class="text-indent padding_5_0">
        5.1.2甲方仅提供租赁房屋，对于乙方承租该房屋使用不负任何责任，亦不承担因乙方原因造成的涉及人身伤亡、财产损失及一切法律、赔偿责任。
      </div>
      <div class="text-indent padding_5_0">
        5.1.3有权按时如数向乙方收取租金、押金，如乙方未按照约定交付的，甲方有权采取合法措施予以追讨或解除合同。
      </div>
      <div class="text-indent padding_5_0">
        5.1.4甲方有权不定期检查乙方对租赁房屋的使用情况，并对不满足本合同约定的现象或行为下达限期整改通知单。
      </div>
      <h4 class="padding_5_0 margin_0 text_ind_17">5.2乙方的权利与义务</h4>
      <div class="text-indent padding_5_0">
        5.2.1按本合同约定向甲方按时交清租金、押金及其他根据合同应支付的费用。
      </div>
      <div class="text-indent padding_5_0">
        5.2.2乙方应当按照合同约定的租赁用途和使用要求合理使用房屋，不得擅自改动房屋承重结构和拆改室内设施设备，不得增加外墙荷载，不得超载使用，不得堆放易燃易爆及危险物品，不得擅自装修，不得损害公共利益和相邻关系人的合法权益。由于乙方使用不当造成房屋及其附属物品、设施设备损坏的，乙方应负责修复或予以经济赔偿。
      </div>
      <div class="text-indent padding_5_0">
        5.2.3租赁期内，乙方未获得甲方书面许可，不得擅自将房屋整体或部分转租或分租，否则视为乙方单方违约，甲方有权单方立即解除合同、没收押金。
      </div>
      <div class="text-indent padding_5_0">
        5.2.4乙方不得利用租赁住房从事违规经营，进行传销、赌博等违法犯罪活动。
      </div>
      <div class="text-indent padding_5_0">
        5.2.5租赁期满或合同终止（解除）后，乙方须及时、完整地向甲方交回租赁房屋。
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">六、合同的解除</h3>
      <div class="text-indent padding_5_0">
        6.1符合下列条件之一的，合同解除：
      </div>
      <div class="text-indent padding_5_0">
        （1）经双方协商一致，可以解除本合同。
      </div>
      <div class="text-indent padding_5_0">
        （2）因不可抗力致使合同无法继续履行的，应当通知对方，本合同自通知到达对方时解除。
      </div>
      <div class="text-indent padding_5_0">
        （3）因一方故意隐瞒与订立合同有关的重要事实或者提供虚假情况的，另一方有权解除合同。
      </div>
      <div class="text-indent padding_5_0">
        （4）乙方在合同签订后1日内不付清约定款项（例如：租金、押金等），视为乙方单方面违约，合同将自动解除，甲方保留追究乙方赔偿损失权利。
      </div>
      <div class="text-indent padding_5_0">
        6.2乙方有以下情形的，甲方有权单方解除合同并扣除押金，但应提前三天通知乙方：
      </div>
      <div class="text-indent padding_5_0">
        1.不按照合同约定支付租金达15日的；
      </div>
      <div class="text-indent padding_5_0">
        2.违反本合同约定，擅自将房屋转租或者出借给他人的；
      </div>
      <div class="text-indent padding_5_0">
        3.擅自改变房屋用途、结构或者实施其他违法建设行为的；
      </div>
      <div class="text-indent padding_5_0">
        4.利用租赁房屋从事违法犯罪活动的；
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">七、违约责任</h3>
      <div class="text-indent padding_5_0">
        7.1租赁期内甲方需提前收回房屋的，或乙方需提前退租的，应至少提前七日书面通知对方，并按 <span class="span_decoration">&nbsp;&nbsp;一个月的房屋租金&nbsp;&nbsp;</span>的标准向对方支付违约金。甲方应退还乙方已交纳但尚未发生的剩余费用。
      </div>
      <div class="text-indent padding_5_0">
        7.2乙方违约擅自将房屋转租给第三人、改变房屋用途、拆改变动损坏房屋主体结构，或利用房屋从事违法活动、损害公共利益的，甲方有权解除合同并要求乙方按照两个月的房屋<span class="span_decoration">&nbsp;&nbsp;&nbsp;&nbsp;租金</span>的标准支付违约金，造成甲方房屋损坏的，乙方还应承担赔偿责任。
      </div>
      <div class="text-indent padding_5_0">
        如本合同约定的违约金不足以弥补守约方因违约方原因导致的实际损失的，违约方仍需承担赔偿责任。
      </div>
      <div class="text-indent padding_5_0">
        7.3租赁期限届满或本合同提前终止（解除）时，若乙方未能按期将租赁房屋完整（完全）交还给甲方或交还不符合条件的，则甲方没收押金，乙方根据实际使用天数，按照租赁折合日租金（月租金/30天）双倍的标准向甲方支付租赁费。每逾期一日，还应承担每月租金之5%的违约金，若违约金不足弥补甲方损失的，乙方应另行赔偿。
      </div>
      <div class="text-indent padding_5_0">
        7.4乙方有下列情形之一，视为乙方单方违约，甲方有权单方立即解除合同、没收押金，不承担乙方因此遭受的任何损失，并且要求乙方赔偿甲方一切损失，乙方应按本合同约定交还房屋：
      </div>
      <div class="text-indent padding_5_0">
        （1）逾期超过15日仍未交清租金的；
      </div>
      <div class="text-indent padding_5_0">
        （2）未经甲方同意，乙方擅自将房屋全部或部分转租、分租，转让或以其他方式给予第三方使用的；
      </div>
      <div class="text-indent padding_5_0">
        （3）未按约定用途使用房屋或利用房屋进行违法、犯罪活动的；
      </div>
      <div class="text-indent padding_5_0">
        （4）乙方违反保障性住房规定的，甲方有权解除合同；
      </div>
      <div class="text-indent padding_5_0">
        7.5本合同约定的乙方出现违约而导致甲方受到损失包括直接损失和间接损失，乙方还应承担不仅限于鉴定评估费、诉讼费、律师费、恢复费等直接和间接费用。
      </div>
      <div class="text-indent padding_5_0">
        7.6除上述各项规定外，本合同任何一方若有违约行为，均应在收到另一方通知后 <span class="span_decoration">&nbsp;&nbsp;7&nbsp;&nbsp;</span>天内予以更正，本合同另有约定的除外;逾期未能更正，另一方保留索赔的权利。
      </div>
      <div class="text-indent padding_5_0">
        7.7本合同项下违约责任或赔偿责任可以累加适用。
      </div>


      <h3 class="padding_5_0 margin_0 text_ind_17">八、不可抗力 </h3>
      <div class="text-indent padding_5_0">
        合同履行期间，因不可抗力（包括但不限于洪水、地震及其他自然灾害、战争，法律、法规、规章以及国家、地方政府政策调整、行政意见）导致本合同无法继续履行的，任何一方均有权提出变更或解除本合同，如因不可抗力导致房屋无法正常使用，甲方可根据实际使用情况给与乙方租金减免（含部分减免），甲乙双方亦有权单方面终止（解除）合同。
      </div>
      <h3 class="padding_5_0 margin_0 text_ind_17">九、其他约定事项 </h3>
      <div class="text-indent padding_5_0">
        9.1因本合同之签订、履行、解除以及解释等引起的所有纠纷，甲乙双方均应当秉承诚信合作的原则予以协商解决。如自争议发生之日起十五日内仍不能达成一致意见的，任何一方均有权向租赁房屋所在地人民法院提起诉讼。
      </div>
      <div class="text-indent padding_5_0">
        9.2本合同项下任何一方向对方发出的通知、信件、数据电文等，应当发送至本合同下列约定的地址、联系人和通信终端：任何一方向对/他方所发出的通知、信件，直接送达的，自签收之日起视为送达；邮寄的，自通知、信件交付邮政特快专递后的第3日（或以挂号信回执上注明的收件日期）视为送达日期；发出的电子邮件或微信文字通知，自前述电子文件内容在发送方正确填写地址且未被系统退回的情况下，发送并进入对方数据电文接收系统即视为送达。
      </div>
      <div class="text-indent padding_5_0">
        一方变更名称、地址、联系人的，应当在变更后3日内及时书面通知对方，对方实际收到变更通知前的送达仍为有效送达，电子送达与书面送达具有同等法律效力。
      </div>
      <div class="text-indent padding_5_0">
        9.3本合同自双方盖章或签字之日起生效，各方权利义务执行完毕后失效。
      </div>
      <div class="text-indent padding_5_0">
        9.4本合同如有未尽事宜，双方可进行协商补充或修订。
      </div>
      <div class="text-indent padding_5_0">
        9.5本合同正本<span class="span_decoration">壹式肆份，甲方执叁份、乙方执壹份</span>。
      </div>
      <div class="text-indent padding_5_0" style="margin-top: 100px">
        附件：1.房屋交接及设备清单
      </div>
      <div class="text-indent padding_5_0" style="margin-left: 50px">
        2.房屋交还及设施设备交还确认书
      </div>
      <div class="text-indent padding_5_0" style="margin-left: 50px">
        3.房屋租赁相关管理规定
      </div>
      <div class="text-indent padding_5_0" style="margin-left: 50px">
        4.房屋及附属设施家电家具相关费用计收标准
      </div>
      <div class="text-indent padding_5_0" style="margin-left: 50px;margin-bottom: 50px">
        5.租金支付周期表
      </div>

      <div style="height: 20px;"></div>
      <div style="margin-left: 5%;">
        <h4>甲方：广西南宁昇达资产投资有限公司 </h4>
        <h4>法定代表人：</h4>
        <h4 style="margin-top: 100px">乙方： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.lesseeName }}&nbsp;&nbsp;</span></h4>
        <h4>身份证号： <span class="span_decoration">&nbsp;&nbsp;{{ cmData.lesseeIdCard }}&nbsp;&nbsp;</span></h4>
<!--        <div style="text-indent: 2em;width: 40%; margin-bottom: 200px">-->
<!--          提供方（签章）： 广西南宁昇达资产投资有限公司<br>-->
<!--&lt;!&ndash;          <img :src="defaultMenuIcon" style="width: 40%"/>&ndash;&gt;-->
<!--        </div>-->
<!--        <div style="text-indent: 2em;width: 40%;">-->
<!--          使用方（签章）：<br>-->
<!--          <img v-show="grSrc!==null" :src="grSrc" style="width: 40%"/>-->
<!--        </div>-->
      </div>
      <div class="flex" style="margin-left: 10%;margin-bottom: 20%">
        <div style="text-indent: 2em;width: 40%;">
          日期：{{ cmData.signDate }}
        </div>
<!--        <div style="text-indent: 2em;width: 40%;">-->
<!--          日期：{{ cmData.signDate }}-->
<!--        </div>-->
      </div>

      <div>
        <h3 style="padding-left: 30px">附件一 房屋交接书</h3>
        <h1 style="text-align: center;">房屋交接书</h1>


        <div class="text-indent padding_5_0">甲方（出租方）：
          <span class="span_decoration">&nbsp;&nbsp;广西南宁昇达资产投资有限公司&nbsp;&nbsp;</span>
        </div>
        <div class="text-indent padding_5_0">乙方（承租方）：
          <span class="span_decoration">&nbsp;&nbsp;{{cmData.lesseeName}}&nbsp;&nbsp;</span>
        </div>
        <div class="text-indent padding_5_0">
          根据双方约定，双方就位于
          <span class="span_decoration">&nbsp;&nbsp;南宁市{{cmData.apartmentAddress}}{{cmData.locationAddress}}（建筑面积：  {{cmData.outArea}} ㎡，套内面积：  {{cmData.useArea}} ㎡）共1套&nbsp;&nbsp;</span>
          房屋进行交接，具体情况如下：
        </div>
        <div class="text-indent padding_5_0">
          一、   <span class="span_decoration">&nbsp;&nbsp; {{cmData.contractStartTime}}&nbsp;&nbsp;</span> 我司将 {{cmData.locationAddress}}房屋资产租赁乙方使用，并进行现场房屋验收,经验收后乙方认为甲方的交付行为完全符合交付时间、条件及标准，乙方同意接受，下一步签订《房屋租赁合同》。

        </div>
        <div class="text-indent padding_5_0">
          二、双方确认，相关物业管理费、水、电、气及其他费用，自本交接日后由乙方全部承担,并与小区物业做好对接工作。
        </div>
        <div class="text-indent padding_5_0">
          三、双方确认已将钥匙交接完毕。
        </div>
        <div class="text-indent padding_5_0">
          四、本房屋交接书自甲乙双方签字或盖章之日起生效。
        </div>
        <div class="text-indent padding_5_0">
          五、本房屋交接书一式二份，甲、乙各执一份，具体同等法律效力。
        </div>
        <div class="text-indent padding_5_0">
          甲方签字：    广西南宁昇达资产投资有限公司                        乙方签字：
        </div>
        <div class="text-indent padding_5_0">
          签署日期： {{ cmData.signDate }}
        </div>
      </div>


<!--      <h3 style="padding-left: 30px">附件二 房屋交付确认书 </h3>-->
<!--      <h1 style="text-align: center;">房屋交付确认书 </h1>-->
<!--&lt;!&ndash;      <div class="text-indent padding_5_0">甲方（出租方）：&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="span_decoration">&nbsp;&nbsp;广西南宁昇达资产投资有限公司&nbsp;&nbsp;</span>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="text-indent padding_5_0">乙方（承租方）：&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="span_decoration">&nbsp;&nbsp;广西南宁昇达资产投资有限公司&nbsp;&nbsp;</span>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="text-indent padding_5_0">&ndash;&gt;-->
<!--&lt;!&ndash;        根据双方约定，双方就位于&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="span_decoration">&nbsp;&nbsp;南宁市青秀区林里桥路8号林里桥家园小区3号楼  单元     号房（建筑面积：   ㎡，套内面积：   ㎡）共1套&nbsp;&nbsp;</span>&ndash;&gt;-->
<!--&lt;!&ndash;        房屋进行交接，具体情况如下：&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div class="text-indent padding_5_0">-->
<!--        一、  交接场地坐落： <span class="span_decoration">&nbsp;&nbsp; 林里桥家园小区   栋   单元    号房&nbsp;&nbsp;</span>-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        二、交接场地使用情况： <span class="span_decoration">&nbsp;&nbsp; 内容暂时未定&nbsp;&nbsp;</span>-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        三、交接场地相关费用明细：-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        <table class="table-padding">-->
<!--          <thead>-->
<!--          <th>水费是否结清	</th>-->
<!--          <th>电费是否结清</th>-->
<!--          <th>物业费是否结清	</th>-->
<!--          <th>有无欠缴未缴的费用</th>-->
<!--&lt;!&ndash;          <th>单位</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>单件金额</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>备注</th>&ndash;&gt;-->

<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for='(item,index) in tableList' :key="index"  style="text-align: center">-->
<!--&lt;!&ndash;            <td>{{index + 1}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;            <td>{{ item.name }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;            <td>{{item.some}}</td>&ndash;&gt;-->
<!--            <td>{{item.box}}</td>-->
<!--            <td>{{item.itr}}</td>-->
<!--            <td>{{item.pic}}</td>-->
<!--            <td>{{item.bei}}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        四、交接场地内各设备详细状况：-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        <table class="table-padding">-->
<!--          <thead>-->
<!--          <th>名称	</th>-->
<!--          <th>数量</th>-->
<!--          <th>使用情况	</th>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for='(item,index) in tableList' :key="index"  style="text-align: center">-->
<!--            <td>{{item.box}}</td>-->
<!--            <td>{{item.itr}}</td>-->
<!--            <td>{{item.pic}}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        出租方：                              承租方 ：-->
<!--      </div>-->
<!--      <div class="text-indent padding_5_0">-->
<!--        年     月     日-->
<!--      </div>-->
<!--    </div>-->

      <div>
        <h3 style="padding-left: 30px">附件三 入住费用参考及注意事项</h3>
        <h1 style="text-align: center;">入住费用参考及注意事项</h1>


        <div class="text-indent padding_5_0">
          1、入住费用我公司仅为房屋租金，物业费、垃圾清运费等需自行去物业缴费。水费、电费为民水民电，电费可在手机支付宝生活缴费中缴费，水费需到物业处进行缴费。
        </div>
        <div class="text-indent padding_5_0">
          2、普通房（不配家具）：租金1100元/月，押一付一，具体根据签订合同为准；
        </div>
        <div class="text-indent padding_5_0">
          3、精装房（配备家具）：租金1400/月，押二付一，具体根据签订合同为准；
        </div>
        <div class="text-indent padding_5_0">
          4、房屋租金为每个月1日前五天内缴纳，按月缴费或按季缴费。
        </div>
        <div class="text-indent padding_5_0">
          5、入住后，应入住后做三件事:①核对电卡剩余度数。②核对水表底数。③检查房间内各结构、配套设施，附属物品。(入住1个月内包修包换,1个月后住户自理）如有问题请在入住当天到2楼项目部提出，并做好登记。
        </div>
        <div class="text-indent padding_5_0">
          6、住户合同到期退房需提前15天告知管理处，管理处可进行预招租，租户需在到期的当天或之前办理。搬完物品做好房间卫生后，携带合同登记的身份证押金单、合同办理退房手续。
        </div>
        <div class="text-indent padding_5_0">
          7、逾期退房违约责任参考合同第七条
        </div>
        <div class="text-indent padding_5_0">
          8、房间钥匙办理或出借均需出示身份证件，核对房间号与姓名，不是本人则需电话确认才可办理,出借还需抵押物品。
        </div>
        <div class="text-indent padding_5_0">
          9、非机动车不可推上楼充电，具体充电位置为：电动车充 回迁5负一 ；新能源充电桩，在18栋1层。
        </div>
        <div class="text-indent padding_5_0">
          注：半年起租；不可养宠物；楼顶不开放，钥匙不外借。
        </div>
        <div class="text-indent padding_5_0">
          承租人：   {{cmData.lesseeName }}                     日期： {{ cmData.signDate }}
        </div>
      </div>

      <div>
        <h3 style="padding-left: 30px">附件四 {{cmData.buildName}}房源家具家电清单</h3>
        <h1 style="text-align: center;">{{cmData.buildName}}房源家具家电清单</h1>
        <table v-if="cmData.annexIVTableHeader === 1" class="table-padding">
          <thead>
          <th>序号	</th>
          <th>物品名称</th>
          <th>	数量	</th>
          <th>规格/型号（或图号、参数）</th>
          <th>单位</th>
          <th>单件金额</th>
          <th>备注</th>

          </thead>
          <tbody>
          <template v-if="cmData.decorationStyle === '精装修' ">
          <tr v-for='(item,index) in cmData.annexIVTable' :key="index"  style="text-align: center">
            <td>{{item.serialNumber}}</td>
            <td>{{item.brandName}}</td>
            <td>{{item.number}}</td>
            <td>{{item.model}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.remark}}</td>
          </tr>
          </template>
          </tbody>
        </table>
<!--        新增表格-->
        <table v-if="cmData.annexIVTableHeader === 2" class="table-padding">
          <thead>
          <th>序号	</th>
          <th>品牌名称</th>
<!--          <th>	数量	</th>-->
          <th>规格型号</th>
          <th>数量</th>
          <th>单位</th>
<!--          <th>备注</th>-->

          </thead>
          <tbody>
          <template v-if="cmData.decorationStyle === '精装修' ">
            <tr v-for='(item,index) in cmData.annexIVTable' :key="index"  style="text-align: center">
              <td>{{item.serialNumber}}</td>
              <td>{{ item.brandName }}</td>
<!--              <td>{{item.some}}</td>-->
              <td>{{item.model}}</td>
              <td>{{item.number}}</td>
              <td>{{item.unit}}</td>
<!--              <td>{{item.bei}}</td>-->
            </tr>
          </template>
          </tbody>
        </table>
        <div class="text-indent padding_5_0">
          备注：如租赁期间房间内物品及其配件发生损坏或遗失，需承租人支付相应价格。
        </div>

        <div class="text-indent padding_5_0">
          承租人(盖章或签字)：   {{cmData.lesseeName }}                     日期： {{ cmData.signDate }}
        </div>
      </div>



      <div>
        <h3 style="padding-left: 30px">附件五 租金支付周期表</h3>
        <h1 style="text-align: center;">租金支付周期表</h1>
        <table class="table-padding">
          <thead>
          <th>租金明细类别	</th>
          <th>套数</th>
          <th>	房源面积（㎡）	</th>
          <th>月租金（元）</th>
          </thead>
          <tbody>
          <tr>
            <td>租金标准</td>
            <td>1</td>
            <td>{{cmData.outArea}}</td>
            <td>{{cmData.unitPrice}}</td>
          </tr>
          </tbody>
        </table>


        <div class="text-indent padding_5_0"  id="mybox" >

        </div>


        <div class="text-indent padding_5_0">
          承租人：   {{cmData.lesseeName }}                     日期： {{ cmData.signDate }}
        </div>
      </div>
      <div style="height: 80px;line-height: 80px;text-align: right;padding-right: 40px;">
        广西南宁昇达资产投资有限公司宣
      </div>
      <div class="flex justify-center" style="margin-top: 30px">
        <el-button type="primary" @click="startSigning">开始签约</el-button>
        <el-button type="success" :disabled="cmData.procedureNo === null || formdata === null "
                   @click="submitSignature">
          提交签名
        </el-button>
      </div>
    </div>
    <div style="height: 500px;">
      <div v-show="isShow" class="flex justify-center">
        <h2>签署区</h2>
      </div>
      <div v-show="isShow" class="flex justify-center">
        <canvas id="canvas" :width="width" :height="height" style="margin: 0 auto;">
          浏览器不支持canvas
          <!-- 如果不支持会显示这段文字 -->
        </canvas>
      </div>
      <div v-show="isShow" class="flex justify-center">
        <el-button type="primary" @click="setBackGround">清空</el-button>
        <el-button type="success" @click="createImage">提交</el-button>
      </div>
    </div>

    <r-e-dialog title="签约验证" :visible.sync="yanzhengIsShow" show-footer top="10vh" width="600px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
      <el-form ref="formPublish" label-width="100px" size="small">
        <div class="flex justify-between">
          <el-form-item label="手机号码">
            <el-input type="text" v-model="cmData.phone" :readonly="true" style="width: 250px;"/>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="验证码">
            <el-input type="number" v-model="smsCode" style="width: 250px;" :max="999999" :min="100000"
                      placeholder="请填写验证码"/>
          </el-form-item>
          <el-form-item label="" label-width="20px">
            <el-button size="mini" :disabled="yzYes" @click="sendSmsCode">
              {{ yzYes ? yzJiShi + "s后获取" : "获取验证码" }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </r-e-dialog>

    <el-dialog title="提示" :visible.sync="tipsIsShow" width="30%" :close-on-click-modal="false"
               :close-on-press-escape="false">
            <span>合同签署已经完成，本页面将在{{ nums }}秒后关闭，点击确定可快速关闭，
                <el-link type="primary" :underline="false" @click="downloadContract">下载合同</el-link>
            </span>
      <span slot="footer">
                <el-button type="primary" @click="closeWindow">确 定</el-button>
            </span>
    </el-dialog>

    <r-e-dialog title="认证" :visible.sync="nuonuoIsShow" show-footer top="10vh" width="600px"
                @click-submit="nuonuoClickSubmit" @click-cancel="nuonuoClickCancel" @close="nuonuoClickCancel">
      <div class="text-center" style="color: red; width: 100%;margin-bottom: 15px">
        请用手机扫描二维码完成认证
      </div>
      <div class="flex justify-center">
        <div style="width: 300px;height: 300px;border: 1px solid #F1F1F3;border-radius: 5px;">
          <el-image class="flex align-center" :src="createFullImageUrl(QRCodeUuid)">
            <div slot="error" style="font-size: 30px;">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">加载中...</div>
          </el-image>
        </div>
      </div>
    </r-e-dialog>
  </div>
</template>
<script>
import {
  createPersonalCharter, sendSmsCode, create, downloadContract, getBzfContractData, expordBzfPdf, getPhoneCheck,
  uploadBzfContract, bzfSignWithCaptcha, bzfSign, personalRegister, getFaceAuthenticationUrl, getNuonuoUser,
  bzfWillingAddress, getSigningStatus
} from "@/api/contract";

import {MessageError, MessageSuccess} from "@custom/message";
import {createFullImageUrl} from "@/components/Upload/index";
import {ParseFloatFormat} from "@/utils/custom";

const defaultMenuIcon = require("@images/BZFZ.png");
export default {
  name: 'test-canvas',
  data() {
    return {
      tableList:[
        {
          name:'卧室空调',
          some:'2',
          box:'美的（Midea）空调挂机KFR-26GW/G3-3 变频冷暖1匹',
          itr:'台',
          pic:'3000',
          bei:''
        },
        {
          name:'客厅空调',
          some:'1',
          box:'美的（Midea）空调挂机变频冷暖2匹KFR-50GW/DN8MXA3',
          itr:'台',
          pic:'4700',
          bei:''
        },
        {
          name:'餐桌',
          some:'1',
          box:'1380*860*750半橡木材质',
          itr:'件',
          pic:'1100',
          bei:''
        },
        {
          name:'餐凳',
          some:'4',
          box:'40*40*87',
          itr:'件',
          pic:'200',
          bei:''
        },
        {
          name:'三人沙发床',
          some:'1',
          box:'1800*1980*700',
          itr:'件',
          pic:'2500',
          bei:''
        },
        {
          name:'窗帘（客厅）',
          some:'1',
          box:'2500*2500，颜色根据业主指定',
          itr:'套',
          pic:'500',
          bei:''
        },
        {
          name:'01-02户型窗帘（主卧）',
          some:'1',
          box:'2300*1820，颜色根据业主指定',
          itr:'套',
          pic:'350',
          bei:''
        },
        {
          name:'01-02户型窗帘（次卧',
          some:'1',
          box:'2000*1820，颜色根据业主指定',
          itr:'套',
          pic:'300',
          bei:''
        },
        {
          name:'03-05户型窗帘（主卧）',
          some:'1',
          box:'2850*1820，颜色根据业主指定',
          itr:'套',
          pic:'400',
          bei:''
        },
        {
          name:'03-05户型窗帘（次卧）',
          some:'1',
          box:'1800*2500，颜色根据业主指定',
          itr:'套',
          pic:'350',
          bei:''
        },
        {
          name:'橡木烤漆一米五床',
          some:'2',
          box:'2070*1510',
          itr:'套',
          pic:'1750',
          bei:''
        },
        {
          name:'红健牌一米五床垫',
          some:'2',
          box:'1490*1900',
          itr:'件',
          pic:'1000',
          bei:''
        },
        {
          name:'床头柜',
          some:'2',
          box:'465*380*480',
          itr:'套',
          pic:'400',
          bei:'实木烤漆压纹床头柜'
        },
        {
          name:'三门衣柜',
          some:'2',
          box:'1200*580*2000',
          itr:'台',
          pic:'1900',
          bei:''
        },
        {
          name:'鞋柜',
          some:'1',
          box:'1200*320*900',
          itr:'台',
          pic:'800',
          bei:''
        },
        {
          name:'冰箱',
          some:'1',
          box:'美的冰箱175升型号：BCD-175CM（ZG)',
          itr:'台',
          pic:'1400',
          bei:''
        },
        {
          name:'抽油烟机',
          some:'1',
          box:'美的CXW-220-B80AT型烟机 按键 玻璃中国山脉灰导流板山脉灰装饰罩无可调装饰罩',
          itr:'台',
          pic:'1200',
          bei:''
        },
        {
          name:'美的燃气热水器',
          some:'1',
          box:'美的燃气热水器型号：JSQ22-12HPC',
          itr:'台',
          pic:'1300',
          bei:''
        },
        {
          name:'美的洗衣机波轮8公斤',
          some:'1',
          box:'美的洗衣机波轮8公斤型号：MB80V33B',
          itr:'台',
          pic:'1200',
          bei:''
        },
        {
          name:'燃气灶',
          some:'1',
          box:'美的JZT-Q60G嵌入式燃气灶 钢化玻璃 双头 天然气，一级能效 电池',
          itr:'台',
          pic:'700',
          bei:''
        }
      ],
      defaultMenuIcon,
      paramData: {},
      cmData: {
        bathroomType: [],
        furnitureType: [],
        appliancesType: [],
        guardType: [],
        otherType: [],
        otherType1: [],
        leftList: [],
        rightList: [],
      },
      pdfBase64: null,
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      penColor: "#000000",
      penWidth: 4,
      penClick: false,
      startAxisX: 0,
      startAxisY: 0,
      brackGroudColor: "#ffffff",
      progressValue: 0.2,
      showTab: 0,

      isShow: false,
      grSrc: null,
      formdata: null,
      charterId: null,

      yanzhengIsShow: false,
      smsCode: null,

      closeWindowSetInterval: null,
      yzYes: false,
      yzJiShi: 120,

      tipsIsShow: false,
      nums: 30,

      QRCodeUuid: null,
      nuonuoIsShow: false,

    }
  },
  props: {
    height: {
      type: Number,
      default: 376
    },
    width: {
      type: Number,
      default: 800
    },
    defaultPenColor: {
      type: String,
      default: '#000000'
    },
    defaultPenSize: {
      type: Number,
      default: 3
    },
    defaultBackGroundColor: {
      type: String,
      default: "rgba(0, 0, 0, 0)"
    },
    toolsTabList: {
      type: Boolean,
      default: false
    }
  },
  created() {
    console.log(this.cmData)
  },
  mounted() {
    let that = this;
    let paramData = localStorage.getItem('paramData') || null;
    if (paramData) {
      paramData = JSON.parse(paramData);
      let {uuid} = paramData;
      uuid = uuid || null;
      that.paramData = {...paramData, uuid};
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      console.log('paramData,我是参数',paramData)
      getBzfContractData(paramData).then(res => {
        let {info} = res;
        setTimeout(() => {
          console.log("info", info);
        },5000)
        let {otherType, rightList, leftList} = info;
        otherType = otherType.filter(item => item.dataValue !== null && item.dataValue !== "0");
        let otherType1 = [];
        let arr = [];
        for (let i = 0; i < otherType.length; i++) {
          if (i % 2 === 0) arr.push(otherType[i]);
          else otherType1.push(otherType[i]);
        }
        info.leftList = leftList.map((item) => {
          const amount = ParseFloatFormat(item.amount);
          return {...item, amount}
        });
        info.rightList = rightList.map((item) => {
          const amount = ParseFloatFormat(item.amount);
          return {...item, amount}
        });
        info.otherType = arr;
        info.otherType1 = otherType1;
        that.cmData = info;
        let ahtml = info.periodicTable
        document.getElementById("mybox").innerHTML = ahtml;
      }).finally(() => loading.close());
    }
    //读取参数后，删除
    localStorage.removeItem('paramData');

    this.init();
  },
  methods: {
    // 开始签约
    async startSigning() {

      let that = this;

      let {lesseeName: name, phone: phone, lesseeIdCard: idcard} = that.cmData;
      phone = phone.trim();
      idcard = idcard.trim();
      if (!name) {
        MessageError('请完善您的姓名');
        return;
      }
      if (!phone) {
        MessageError('请完善您的手机号');
        return;
      }
      if (!idcard) {
        MessageError('请完善您的身份证号');
        return;
      }
      // alert('预开始')
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      const info = await that.getNuonuoUser(name, idcard, phone);
      // alert('开始')
      if (info) {
        // alert('存在')
        loading.close();
        const {certified} = info;
        if (certified === 0) {
          that.getFaceAuthenticationUrl();
        } else {
          // alert('不粗在')
          that.createNuoNuoContact();
        }
      }
    },

    async createNuoNuoContact() {
      const that = this;
      let paramData = that.paramData;

      await that.getSigningStatus(paramData.uuid);

      let cmData = that.cmData;
      const {procedureNo} = cmData;
      if (procedureNo) {
        that.isShow = true;
        return;
      }
      if (paramData.uuid === null) {
        this.$confirm('是否检查完合同, 是否开始签署?', '提示', {type: 'warning'}).then(async () => {
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          let res = await create(paramData);
          let {info: {uuid}} = res;
          that.paramData = {...paramData, uuid};
          expordBzfPdf(uuid).then(res => {
            const blob = new Blob([res], {type: "application/pdf;chartset=UTF-8"});
            that.blobToBase64(blob, (dataurl) => {
              let base64 = dataurl.split("data:application/pdf;chartset=utf-8;base64,");
              that.pdfBase64 = base64[1];
              that.uploadContract();
            });
          }).finally(() => loading.close());
        });
      } else {
        const loadingOptions = that.loadingOptions;
        const loading = that.$loading({...loadingOptions});
        expordBzfPdf(paramData.uuid).then(res => {
          const blob = new Blob([res], {type: "application/pdf;chartset=UTF-8"});
          that.blobToBase64(blob, (dataurl) => {
            let base64 = dataurl.split("data:application/pdf;chartset=utf-8;base64,");
            that.pdfBase64 = base64[1];
            that.uploadContract();
          });
        }).finally(() => loading.close());
      }
    },

    uploadContract() {
      const that = this;
      let paramData = that.paramData;
      let {uuid} = paramData;
      let base64 = that.pdfBase64;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      console.log(base64, uuid)
      uploadBzfContract({base64, uuid}).then(res => {
        let {msg} = res;
        that.cmData.procedureNo = msg;
        that.isShow = true;
      }).catch(err => {
        if (err) {
          const {data: {resultCode = 1001}} = err;
          if (resultCode === 1009) {
            setTimeout(() => that.closeWindow(), 3 * 1000);
          }
        }
      }).finally(() => loading.close());
    },

    async submitSignature() {
      let that = this;
      let formdata = that.formdata;
      const {uuid} = that.paramData;
      if (formdata) {
        await that.getSigningStatus(uuid);

        const loadingOptions = that.loadingOptions;
        const loading = that.$loading({...loadingOptions});
        console.log(formdata)
        createPersonalCharter(formdata).then(res => {
          let {info} = res;
          let {charterId} = info;
          that.charterId = charterId;
          this.$confirm('请选择签署方式，注：短信验证签署请确保当前手机号是当前身份证号办理, 否则会收不到短信验证码', '提示', {
            confirmButtonText: '人脸意愿签署',
            cancelButtonText: '短信验证签署',
            type: 'warning',
            center: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
          }).then(() => {
            that.bzfWillingAddress();
          }).catch(() => {
            that.yanzhengIsShow = true;
          });
        }).catch(err => {
          that.grSrc = null;
          that.startSigning();
          that.formdata = null;
        }).finally(() => loading.close());
      } else {
        that.yanzhengIsShow = false;
        MessageError("未签名,无法提交");
      }
    },

    async sendSmsCode() {
      let that = this;
      let paramData = that.paramData;
      let {uuid} = paramData;

      await that.getSigningStatus(uuid);

      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      sendSmsCode(uuid).then(res => {
        MessageSuccess("短信发送成功");
        that.yzYes = true;
        let jishi = setInterval(() => that.yzJiShi = that.yzJiShi - 1, 1000);
        setTimeout(() => {
          clearInterval(jishi);
          that.yzJiShi = 120;
          that.yzYes = false;
        }, 120 * 1000);
      }).finally(() => loading.close());
    },

    async clickSubmit() {
      let that = this;
      let paramData = that.paramData;
      let {uuid} = paramData;

      await that.getSigningStatus(uuid);

      let smsCode = that.smsCode;
      let charterId = that.charterId;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      if (smsCode === null || smsCode === "") {
        MessageError("请输入验证码");
        return;
      }
      bzfSignWithCaptcha({uuid, smsCode, charterId}).then(res => {
        bzfSign({uuid}).then(r => {
          MessageSuccess("签约成功");
          that.clickCancel();
          that.tipsIsShow = true;
          that.closeWindowSetInterval = setInterval(() => that.nums = that.nums - 1, 1000);
          setTimeout(() => that.closeWindow(), 30 * 1000);
        }).finally(() => loading.close());
      }).finally(() => loading.close());
    },

    clickCancel() {
      this.yzYes = false;
      this.smsCode = null;
    },

    closeWindow() {
      let that = this;
      clearInterval(that.closeWindowSetInterval);
      window.opener = null;
      window.open('', '_self');
      window.close();
    },

    downloadContract() {
      let that = this;
      let paramData = that.paramData;
      let {uuid} = paramData;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      downloadContract(uuid).then(res => {
        let {msg} = res;
        if (msg) {
          let blob = that.base64ToBlob(msg);
          let pdfUrl = window.URL.createObjectURL(blob);
          let tempwindow = window.open("", '_blank');
          tempwindow.location = pdfUrl;
        }
      }).finally(() => loading.close());
    },

    //诺诺个人认证查询
    async getNuoNuoAuthentication() {
      let that = this;
      let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber} = that.cmData;
      phone = phone.trim();
      idNumber = idNumber.trim();
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      const {info} = await personalRegister({userName, idNumber, phone}).finally(() => loading.close());
      return info;
    },

    // 获取诺诺人脸认证二维码
    getFaceAuthenticationUrl() {
      let that = this;
      let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber} = that.cmData;
      phone = phone.trim();
      idNumber = idNumber.trim();
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      getFaceAuthenticationUrl({userName, idNumber, phone}).then(res => {
        let {msg} = res;
        that.nuonuoIsShow = true;
        that.QRCodeUuid = msg;
      }).finally(() => loading.close());
    },
    //诺诺人脸认证二维码窗体确认
    async nuonuoClickSubmit() {
      let that = this;
      const {certified} = await that.getNuoNuoAuthentication();
      if (certified === 0) {
        MessageError("请先完成认证");
      } else {
        that.nuonuoClickCancel();
        that.createNuoNuoContact();
      }
    },

    //诺诺人脸认证二维码窗体关闭
    nuonuoClickCancel() {
      let that = this;
      that.nuonuoIsShow = false;
      that.code = null;
      that.orderNo = null;
    },

    //诺诺获取人脸意愿认证地址
    bzfWillingAddress() {
      console.log('我是人脸和短信')
      let that = this;
      let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber, procedureNo} = that.cmData;
      phone = phone.trim();
      idNumber = idNumber.trim();
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      bzfWillingAddress({userName, phone, idNumber, procedureNo}).then(res => {
        that.$alert('已通过短息形式，将人脸意愿签署链接发送至当前手机，请租户收到短信后，将短信链接复制到手机浏览器中，进行认证，认证完成后自动签署完成', '提醒', {
          confirmButtonText: '确定',
          callback: () => {
            that.closeWindow();
          }
        });
      }).finally(() => loading.close());
    },

    //页面初始化
    init() {
      let height = this.height;
      let width = this.width;
      if (width === -1) {
        const cbw = document.getElementById('canvas-broad');
        width = cbw.offsetWidth * 0.9;
        height = cbw.offsetHeight * 0.6;
        this.width = width;
        this.height = height;
      }
      this.penColor = this.defaultPenColor;
      this.brackGroudColor = this.defaultBackGroundColor;
      this.penWidth = this.defaultPenSize;

      let canvas = document.getElementById('canvas'); //获取canvas标签
      let ctx = canvas.getContext("2d");//创建 context 对象
      ctx.fillStyle = this.brackGroudColor;//画布背景色
      ctx.fillRect(0, 0, width, height);//在画布上绘制 width * height 的矩形，从左上角开始 (0,0)
      canvas.addEventListener("mousemove", this.drawing); //鼠标移动事件
      canvas.addEventListener("mousedown", this.penDown); //鼠标按下事件
      canvas.addEventListener("mouseup", this.penUp); //鼠标弹起事件
    },

    setBackGround() {
      const canvas = document.getElementById('canvas'); //获取canvas标签
      const ctx = canvas.getContext("2d");//创建 context 对象
      ctx.clearRect(0, 0, this.width, this.height);//在画布上绘制 600x300 的矩形，从左上角开始 (0,0)
    },

    penDown(event) {
      this.penClick = true;
      this.startAxisX = event.pageX;
      this.startAxisY = event.pageY;
    },

    penUp() {
      this.penClick = false;
    },

    drawing(event) {
      if (!this.penClick) return;
      const canvas = document.getElementById('canvas'); //获取canvas标签
      const ctx = canvas.getContext("2d");//创建 contextconst canvas = document.getElementById('canvas');  对象
      const stopAxisX = event.pageX;
      const stopAxisY = event.pageY;
      const left = document.getElementById('leftMenu');
      const lw = left && left.offsetWidth ? (left.offsetWidth || 0) / 2 : 0;
      ctx.beginPath();
      //由于整体设置了水平居中，因此需要做特殊处理：window.screen.availWidth/2 -300
      const wsaW = window.screen.availWidth;
      const cl = canvas.offsetLeft;
      const ct = canvas.offsetTop;
      ctx.moveTo(this.startAxisX - cl, this.startAxisY - ct);//moveTo(x,y) 定义线条开始坐标
      ctx.lineTo(stopAxisX - cl, stopAxisY - ct);//lineTo(x,y) 定义线条结束坐标
      ctx.strokeStyle = this.penColor;
      ctx.lineWidth = this.penWidth;
      ctx.lineCap = "round";
      ctx.stroke();// stroke() 方法来绘制线条
      this.startAxisX = stopAxisX;
      this.startAxisY = stopAxisY;
    },
    // 画布提交代码
    createImage() {
      let that = this;
      let cmData = that.cmData;
      let {lesseeIdCard} = cmData;
      const canvas = document.getElementById('canvas'); //获取canvas标签
      const img_png_src = canvas.toDataURL("image/png"); //将画板保存为图片格式的函数
      // console.log('=====', img_png_src);//data:image/png;base64,iVBOR.....
      this.grSrc = img_png_src;
      this.isShow = false;
      const file = this.dataURLtoFile(img_png_src, 'png1');
      const formdata = new FormData();
      // 这里只是基本设置，对应接口需求设置响应的类型属性值
      formdata.set('file', file);
      formdata.set("Idcard", lesseeIdCard);
      console.log(formdata)
      this.formdata = formdata;
      that.setBackGround();
    },

    //将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },

    blobToBase64(blob, callback) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        callback(e.target.result);
      };
      fileReader.readAsDataURL(blob);
    },

    base64ToBlob(code) {
      //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
      code = code.replace(/[\n\r]/g, "");
      let raw = window.atob(code);
      let rawLength = raw.length;
      //转换成pdf.js能直接解析的Uint8Array类型
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], {type: "application/pdf"}); //转成pdf类型
    },

    //图片回显
    createFullImageUrl(uuid) {
      return createFullImageUrl(uuid)
    },

    async getNuonuoUser(userName, idNumber, phone) {
      const data = {userName, idNumber, phone}
      const res = await getNuonuoUser(data);
      if (res) {
        const {info} = res;
        return info;
      } else {
        return null;
      }
    },

    async getSigningStatus(uuid) {
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      const res = await getSigningStatus({uuid}).finally(() => loading.close());
      if (res) {
        const {info} = res;
        if (info) {
          const {procedureStatus, procedureNo} = info;
          if (procedureStatus === 0) {
            this.cmData.procedureNo = null;
          }
          if (procedureStatus === 1) {
            this.cmData.procedureNo = procedureNo;
          }
          if (procedureStatus === 2) {
            this.$alert('当前电子合同已签署完成，请勿重复签署', '提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.closeWindow();
              }
            });
          }
        } else {
          this.$alert('当前流程异常，请稍后重试', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.closeWindow();
            }
          });
        }
      } else {
        this.$alert('当前流程异常，请稍后重试', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.closeWindow();
          }
        });
      }
    }
  },
  watch: {
    brackGroudColor: {
      handler(newVal, oldVal) {
        this.setBackGround();
      }
    }
  },
}
</script>

<style lang="scss" scoped="scoped">

#canvas {
  border: 2px solid #ff6700;
  cursor: crosshair;
  /*不能用这种方式给canvas设置宽高*/
  /*width: 600px;*/
  /*height: 300px;*/
}

.text-indent {
  text-indent: 2em;
}

.padding_5_0 {
  padding: 5px 0;
}

.margin_0 {
  margin: 0;
}

.text_ind_17 {
  text-indent: 1.7em;
}

.span_decoration {
  border-bottom: 1px black solid;
  padding: 0 10px;
}

//chen
.table-padding {
  border-collapse: collapse;
  //width: 800px;
  width: 100%;
  margin: 0 auto;
  height: 100px;
}
.table-padding th,
.table-padding td {
  width: 200px;
  border: 1px solid black;
  padding: 10px;
}

</style>
